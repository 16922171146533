"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var __jsx = _react.default.createElement;
// Note: this is currently the only way to generate different messages for the reviewTerm
// because we use babel-plugin-react-intl and this plugin needs the id to be static
// (so no string interpolation id={`detail.reviewStats.reviewTerm.variabel-name`} or id={variable-name} support).
const FormattedReviewTerm = ({
  average
}) => {
  if (average === 10) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "MZ4c5p",
      defaultMessage: [{
        "type": 0,
        "value": "Uitmuntend"
      }]
    });
  }
  if (average >= 9.5) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "nyXg7i",
      defaultMessage: [{
        "type": 0,
        "value": "Voortreffelijk"
      }]
    });
  }
  if (average >= 9) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "Cdf9Cb",
      defaultMessage: [{
        "type": 0,
        "value": "Fantastisch"
      }]
    });
  }
  if (average >= 8.6) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "D0ab/2",
      defaultMessage: [{
        "type": 0,
        "value": "Heerlijk"
      }]
    });
  }
  if (average >= 8) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "oW7y0p",
      defaultMessage: [{
        "type": 0,
        "value": "Erg goed"
      }]
    });
  }
  if (average >= 7) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "W6kNy3",
      defaultMessage: [{
        "type": 0,
        "value": "Goed"
      }]
    });
  }
  return __jsx(_reactIntl.FormattedMessage, {
    id: "lRangn",
    defaultMessage: [{
      "type": 0,
      "value": "Beoordeling"
    }]
  });
};
var _default = exports.default = FormattedReviewTerm;