"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _FormattedReviewTerm = _interopRequireDefault(require("./FormattedReviewTerm"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Star = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Star.svg"));
var __jsx = _react.default.createElement;
const Rating = ({
  value
}) => __jsx(Container, null, __jsx(_Star.default, null), __jsx(_Body.default, {
  variant: "small"
}, __jsx(_reactIntl.FormattedNumber, {
  value: value
}), " ", __jsx(_FormattedReviewTerm.default, {
  average: value
})));
var _default = exports.default = Rating;
const Container = _styledComponents.default.div.withConfig({
  displayName: "Rating__Container",
  componentId: "sc-1ulrp6q-0"
})(["display:flex;align-items:center;color:", ";> p{margin-left:", ";font-weight:700;}> svg{margin-top:-", ";width:1.6rem;}margin-bottom:0;"], ({
  theme
}) => theme.colorAssignments.rating ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.rating) : theme.colors.secondary['30'], ({
  theme
}) => theme.spacing['20_Tiny'], ({
  theme
}) => theme.spacing['10_Micro']);