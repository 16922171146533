"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _react = _interopRequireDefault(require("react"));
var _FavoriteButton = _interopRequireWildcard(require("./FavoriteButton"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const FavoriteIconButton = props => __jsx(_FavoriteButton.default, (0, _extends2.default)({}, props, {
  InactiveButtonComponent: InactiveFavoriteIconButton,
  ActiveButtonComponent: ActiveFavoriteIconButton,
  Spinner: Spinner
}));
const InactiveFavoriteIconButton = props => __jsx(BaseButton, (0, _extends2.default)({}, props, {
  onClick: e => {
    e.preventDefault();
    props.onClick?.(e);
  }
}), __jsx(_FavoriteButton.InactiveHeart, null));
const ActiveFavoriteIconButton = props => __jsx(BaseButton, (0, _extends2.default)({}, props, {
  onClick: e => {
    e.preventDefault();
    props.onClick?.(e);
  }
}), __jsx(_FavoriteButton.ActiveHeart, null));
const Spinner = () => __jsx(InactiveFavoriteIconButton, {
  disabled: true,
  onClick: e => e.preventDefault()
});
const BaseButton = _styledComponents.default.button.withConfig({
  displayName: "FavoriteIconButton__BaseButton",
  componentId: "xw2td4-0"
})(["appearance:none;margin:0;padding:0;svg{margin:0;}", ""], ({
  disabled
}) => disabled && (0, _styledComponents.css)(["opacity:0.3;cursor:progress;"]));
var _default = exports.default = FavoriteIconButton;